<template>
  <main id="why">
    <!-- Video -->
    <v-sheet id="about-video">
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              How Can My One Rule Benefit You?
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="rounded-lg" elevation="4" data-aos="fade-up">
              <v-carousel height="auto" hide-delimiters show-arrows-on-hover>
                <v-carousel-item>
                  <v-img :aspect-ratio="16/9" width="100%">
                    <iframe src="https://www.youtube.com/embed/fYvYkECIeiU" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </v-carousel-item>
                <v-carousel-item>
                  <v-img :aspect-ratio="16/9" width="100%">
                    <iframe src="https://www.youtube.com/embed/iUVo902tFPU" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- With/Without -->
    <v-sheet id="reduce-improve" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="py-8" style="max-width: 1280px">
        <v-row justify="center">
          <!-- Reduce -->
          <v-col cols="12" md="6">
            <v-card class="rounded-lg" style="overflow: hidden" elevation="4" data-aos="fade-right">
              <v-card-text>
                <div class="text-h6 red--text text--darken-3">
                  Some Things My One Rule Can Reduce
                </div>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-sheet color="grey lighten-3">
                <v-btn class="ma-6" color="red lighten-2" @click="reduceDialog = true" large>
                  See List
                </v-btn>
              </v-sheet>

              <!-- Reduce Modal -->
              <v-dialog v-model="reduceDialog" max-width="350px" scrollable>
                <v-card class="rounded-lg d-flex flex-column flex-grow-1" elevation="4">
                  <v-card-title>
                    <div class="text-h6 red--text text--darken-3">
                      My One Rule Can Reduce:
                    </div>
                  </v-card-title>
                  <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                  <v-card-text class="black--text" style="height: 300px">
                    <v-row justify="center">
                      <v-col cols="6">
                        <ul class="text-body-1 text-right pa-0" style="list-style: none">
                          <li>Bullying</li>
                          <li>Hunger</li>
                          <li>Fighting</li>
                          <li>Racism</li>
                          <li>Cheating</li>
                          <li>Lying</li>
                          <li>Selfishness</li>
                          <li>Rape</li>
                          <li>Murder</li>
                          <li>War</li>
                          <li>Regret</li>
                          <li>Coveting</li>
                          <li>Hate</li>
                          <li>Abuse</li>
                          <li>Nastiness</li>
                        </ul>
                      </v-col>
                      <v-col cols="6">
                        <ul class="text-body-1 text-left pa-0" style="list-style: none">
                          <li>Harmful</li>
                          <li>Government</li>
                          <li>Hopelessness</li>
                          <li>Community Fears</li>
                          <li>Riots</li>
                          <li>Stealing</li>
                          <li>Adultery</li>
                          <li>Domestic Violence</li>
                          <li>School Shootings</li>
                          <li>Slavery</li>
                          <li>Hate Speech</li>
                          <li>National Debt</li>
                          <li>Mean People</li>
                        </ul>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col>
                        <p class="text-body-1 mb-0">
                          And about a million other things!
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="reduceDialog = false" text>
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>

          <!-- Improve -->
          <v-col cols="12" md="6">
            <v-card class="rounded-lg" style="overflow: hidden" elevation="4" data-aos="fade-left">
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3">
                  Some Things My One Rule Can Improve
                </div>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-sheet color="grey lighten-3">
                <v-btn class="ma-6" color="primary" @click="improveDialog = true" large>
                  See List
                </v-btn>
              </v-sheet>
              <v-dialog v-model="improveDialog" max-width="350px" scrollable>
                <v-card class="rounded-lg d-flex flex-column flex-grow-1" elevation="4">
                  <v-card-title>
                    <div class="text-h6 blue--text text--darken-3">
                      My One Rule Can Improve:
                    </div>
                  </v-card-title>
                  <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                  <v-card-text class="black--text" style="height: 300px">
                    <v-row justify="center">
                      <v-col cols="6">
                        <ul class="text-body-1 text-right pa-0" style="list-style: none">
                          <li>Kindness</li>
                          <li>Goodness</li>
                          <li>Helpfulness</li>
                          <li>Health</li>
                          <li>Respect</li>
                          <li>Safer Neighborhoods</li>
                        </ul>
                      </v-col>
                      <v-col cols="6">
                        <ul class="text-body-1 text-left pa-0" style="list-style: none">
                          <li>Hope</li>
                          <li>Love</li>
                          <li>Joy</li>
                          <li>Relationships</li>
                          <li>Happier Families and Communities</li>
                        </ul>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col>
                        <p class="text-body-1 mb-0">
                          And about a million other things!
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="improveDialog = false" text>
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Why Should I -->
    <v-sheet id="why-should-i">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              See What My One Rule Can Do For You
            </div>
          </v-col>
        </v-row>
        <!-- Ages -->
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="rounded-lg" style="overflow: hidden" elevation="4">
              <v-toolbar class="hidden-sm-and-down" color="primary" dark flat>
                <v-toolbar-title>
                  Browse By Age Group
                </v-toolbar-title>
                <template v-slot:extension>
                  <v-tabs v-model="ageTab" background-color="primary" grow dark>
                    <v-tab v-for="tab in ageTabs" :key="tab.label">
                      {{ tab.label }}
                    </v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>
              <!-- Mobile-Only Menu -->
              <v-toolbar class="hidden-md-and-up" color="primary" dark flat>
                <v-toolbar-title>
                  Browse By Age Group
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu class="hidden-md-and-up">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="px-0" v-bind="attrs" v-on="on" icon>
                      <v-icon>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(tab, i) in ageTabs"
                      :key="tab.label"
                      @click="ageTab = i"
                    >
                      {{ tab.label }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-tabs-items v-model="ageTab">
                <v-tab-item v-for="tab in ageTabs" :key="tab.label">
                  <v-card class="text-left" flat>
                    <v-container class="px-8">
                      <v-row class="hidden-md-and-up">
                        <v-col>
                          <h6 class="text-h6">{{ tab.label }}</h6>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-col cols="12" md="6">
                          <h6 class="text-h6 mb-4">
                            Without My One Rule,
                          </h6>
                          <ul class="text-body-1 pl-0" style="list-style: none">
                            <li v-for="(wo, i) in tab.withouts" :key="'wo-' + i">
                              ...{{ wo }}
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="12" md="6">
                          <h6 class="text-h6 mb-4">
                            With My One Rule,
                          </h6>
                          <ul class="text-body-1 pl-0" style="list-style: none">
                            <li v-for="(wi, i) in tab.withs" :key="'wi-' + i">
                              ...{{ wi }}
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

        <!-- Issues -->
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="rounded-lg" style="overflow: hidden" elevation="4">
              <v-toolbar class="hidden-sm-and-down" color="primary" dark flat>
                <v-toolbar-title>
                  Browse By Major Issue
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <template class="hidden-sm-and-down" v-slot:extension>
                  <v-tabs v-model="issueTab" background-color="primary" grow dark>
                    <v-tab v-for="tab in issueTabs" :key="tab.label">
                      {{ tab.label }}
                    </v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>
              <!-- Mobile-Only Menu -->
              <v-toolbar class="hidden-md-and-up" color="blue-grey" dark flat>
                <v-toolbar-title>
                  Browse By Major Issue
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu class="hidden-md-and-up">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="px-0" v-bind="attrs" v-on="on" icon>
                      <v-icon>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(tab, i) in issueTabs"
                      :key="tab.label"
                      @click="issueTab = i"
                    >
                      {{ tab.label }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-tabs-items v-model="issueTab">
                <v-tab-item v-for="tab in issueTabs" :key="tab.label">
                  <v-card class="text-left" flat>
                    <v-container class="pl-8">
                      <v-row class="hidden-md-and-up">
                        <v-col>
                          <h6 class="text-h6">{{ tab.label }}</h6>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-col cols="12" md="6">
                          <h6 class="text-h6 mb-4">
                            Without My One Rule,
                          </h6>
                          <ul class="text-body-1 pl-0" style="list-style: none">
                            <li v-for="(wo, i) in tab.withouts" :key="'wo-' + i">
                              ...{{ wo.toLowerCase() }}
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="12" md="6">
                          <h6 class="text-h6 mb-4">
                            With My One Rule,
                          </h6>
                          <ul class="text-body-1 pl-0" style="list-style: none">
                            <li v-for="(wi, i) in tab.withs" :key="'wi-' + i">
                              ...{{ wi.toLowerCase() }}
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Reminders -->
    <v-sheet id="reminders" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="py-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="d-flex flex-column flex-grow-1 rounded-lg" elevation="4">
              <v-card-text class="flex-grow-1">
                <h5 class="text-h5 blue--text text--darken-3 mb-3">
                  Sign the Pledge
                </h5>
                <p class="text-body-1 black--text mb-0">
                  Make the world a better place by joining My One Rule and receive a customized certificate via email.
                </p>
              </v-card-text>
              <v-btn class="mx-auto mb-6" color="primary" @click="$vuetify.goTo('https://myonerule.com/#join')" large>
                Sign The Pledge
                <v-icon right dark>
                  mdi-draw
                </v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="d-flex flex-column flex-grow-1 rounded-lg" elevation="4">
              <v-card-text class="flex-grow-1">
                <h5 class="text-h5 blue--text text--darken-3 mb-3">
                  Refer a Friend or Organization
                </h5>
                <p class="text-body-1 black--text mb-0">
                  Send a customized email to a friend or organization to let them know how My One Rule can change things for the better.
                </p>
              </v-card-text>
              <v-btn class="mx-auto mb-6" color="primary" @click="shareModal = true" large>
                Spread The Word
              </v-btn>
            </v-card>

            <!-- Social Sharing Dialog -->
            <v-dialog v-model="shareModal" persistent max-width="290">
              <v-card>
                <v-card-title class="justify-center">
                  <h6 class="text-h6 text-center mb-2">
                    Spread the Word
                  </h6>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                  <ShareNetwork
                    style="text-decoration: none"
                    network="facebook"
                    url="https://myonerule.com"
                    title="Check out My One Rule!"
                    hashtags="myonerule"
                    >
                    <v-btn class="mb-6" color="primary" block>
                      Facebook
                    </v-btn>
                  </ShareNetwork>
                  <ShareNetwork
                    style="text-decoration: none"
                    network="twitter"
                    url="https://myonerule.com"
                    title="Check out My One Rule!"
                    hashtags="myonerule"
                    >
                    <v-btn class="mb-6" color="blue lighten-1" block dark>
                      Twitter
                    </v-btn>
                  </ShareNetwork>
                  <ShareNetwork
                    style="text-decoration: none"
                    network="email"
                    url="https://myonerule.com"
                    title="Check out My One Rule!"
                    hashtags="myonerule"
                    >
                    <v-btn color="black" block dark>
                      Email
                    </v-btn>
                  </ShareNetwork>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn color="green darken-1" text @click="shareModal = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col class="d-flex" cols="12" md="4">
            <v-card class="d-flex flex-column flex-grow-1 rounded-lg" elevation="4">
              <v-card-text>
                <h5 class="text-h5 blue--text text--darken-3 mb-3">
                  Shop Reminders
                </h5>
                <p class="text-body-1 black--textmb-0">
                  Life happens fast sometimes and it is easy to forget how you want to live. With these powerful reminders, that you will see often during the day, 
                  it will help you to make better decisions and be the person you want to be.
                </p>
              </v-card-text>
              <v-btn class="mx-auto mb-6" color="error" href="https://shop.myonerule.com/" large>
                Shop Reminders
                <v-icon right dark>
                  mdi-cart
                </v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </main>
</template>

<script>
export default {
  name: "Why",
  data: () => ({
    reduceDialog: false,
    improveDialog: false,
    shareModal: false,

    ageTab: 0,
    issueTab: 0,

    ageTabs: [
      { 
        label: "All Ages",
        withs: [
          "you think less about you and more about everyone else!",
          "everyone smiles and there’s a twinkle in their eyes!",
          "respect for all can be seen!",
          "we always take personal responsibility even when it’s tough!",
          "loyal friends are everywhere!",
          "envy is replaced by people being nice!",
          "there is less obstruction and goodwill thrives!",
          "everyone works hard to make education systems great!",
        ],
        withouts: [
          "you just think about yourself.",
          "people sometimes smile and say hi.",
          "there is some respect for you and me.",
          "we sometimes take personal responsibility for us.",
          "loyal friends are rare.",
          "we have lots of envy and strife.",
          "there is lots of obstruction and no compromise.",
          "education systems are okay.",
        ]
      },
      { 
        label: "5-9 Years",
        withs: [
          "kids are more nice and fun.",
          "my brother or sister is not as mean.",
          "my teacher is never rude!",
          "people do not want to lie so they tell the truth.",
          "you would cry less!",
          "people would help me whenever I needed it!",
          "all grown-ups smile when they see you!",
          "less people go hungry.",
        ],
        withouts: [
          "kids are sometimes nice.",
          "my brother or sister can be really mean.",
          "my teacher is sometimes in a good mood.",
          "people lie.",
          "kids sometimes laugh at you and make you cry.",
          "people would sometimes help me.",
          "some grown-ups smile at you.",
          "some people go hungry.",
        ]
      },
      { 
        label: "10-11 Years",
        withs: [
          "kids are more nice and fun.",
          "my brother or sister is not as mean.",
          "my teacher is never rude!",
          "people do not want to lie so they tell the truth.",
          "you would cry less!",
          "people would help me whenever I needed it!",
          "all grown-ups smile when they see you!",
          "less people go hungry.",
        ],
        withouts: [
          "kids are sometimes nice.",
          "my brother or sister can be really mean.",
          "my teacher is sometimes in a good mood.",
          "people lie.",
          "kids sometimes laugh at you and make you cry.",
          "people would sometimes help me.",
          "some grown-ups smile at you.",
          "some people go hungry.",
        ]
      },
      { 
        label: "12-13 Years",
        withs: [
          "there are far fewer bullies.",
          "less people make fun of me.",
          "my teacher is never rude!",
          "people do not want to lie so they tell the truth.",
          "less people laugh when you mess up.",
          "people help me whenever I need it!",
          "I know all my friends have my back.",
          "less people cheat in competitions.",
        ],
        withouts: [
          "there are many bullies.",
          "mean kids make fun of the way I look.",
          "my teacher is sometimes in a good mood.",
          "people lie.",
          "people laugh at mistakes.",
          "people sometimes help me.",
          "some friends stick up for me if someone is being mean.",
          "some people cheat at sports and get mad if they lose.",
        ]
      },
      { 
        label: "14-18 Years",
        withs: [
          "my family and friends and others try to understand me more often.",
          "less people gossip.",
          "my teacher is never rude!",
          "Mom or Dad will always hear me out in an argument.",
          "people help me whenever I need it!",
          "I know all my friends have my back.",
          "less people drink and do drugs.",
        ],
        withouts: [
          "some people try to understand me.",
          "people talk about others behind their back.",
          "my teacher is sometimes in a good mood.",
          "Mom or Dad will sometimes hear me out in an argument.",
          "some people help me.",
          "some friends stickup for me if someone is being mean.",
          "some people drink and do drugs.",
        ]
      },
      { 
        label: "19-23 Years",
        withs: [
          "there is far less hate and it decreases daily.",
          "college is more affordable and the price decreases over time.",
          "people are much more respectful to people of all ages.",
          "there are many more with financial security.",
          "friendships and relationships are much stronger.",
          "you can be more attractive.",
          "far less go hungry.",
          "healthcare is much more affordable and it decreases over time.",
        ],
        withouts: [
          "there is a lot of hate.",
          "college is expensive and getting more expensive every year.",
          "people are not very respectful.",
          "there are few with financial security.",
          "there are some good relationships and friendships.",
          "you can be attractive.",
          "many go hungry.",
          "healthcare comes with a high cost and it continues to increase.",
        ]
      },
      { 
        label: "24-39 Years",
        withs: [
          "there are less misunderstandings and anger.",
          "there is much less fear of war and conflict.",
          "there is much less government corruption.",
          "there are far more economic opportunities.",
          "you will be able to resolve much more conflict.",
          "you can be more attractive.",
          "there is much more respect for others.",
          "far less would go hungry.",
        ],
        withouts: [
          "there are too many misunderstandings and anger.",
          "there is fear of war and conflict.",
          "there is government corruption.",
          "there are some economic opportunities.",
          "you may be able to resolve some conflict.",
          "you can be attractive.",
          "there is some respect for others.",
          "many go hungry.",
        ]
      },
      { 
        label: "40-55 Years",
        withs: [
          "my impatience melts away, and I show I care!",
          "I see work and money as a gift and strive expectations to exceed!",
          "my focus is calm and positive and my heart is more glad!",
          "I focus less on me and others needs I see!",
          "the overspending slows because I am happy with what I have!",
          "I learn to dream big again and build something that’s good and right!",
          "I understand my problems are a gift to grow bigger everyday!",
          "my insecurities and fear decrease, and I have faith it will get better!",
        ],
        withouts: [
          "I can become impatient with my family and peers.",
          "my employer can sometimes be generous to me.",
          "it is hard to focus on the right things and not dwell on the bad.",
          "I tend to focus on myself and my own needs.",
          "I can overspend and not save for a future day.",
          "I can sometimes feel inspired by life.",
          "I can handle my problems okay.",
          "I can get scared and stressed and worry about the future.",
        ]
      },
      { 
        label: "56-74 Years",
        withs: [
          "caring for aging parents is much less stressful and there is a lot of support.",
          "there are far fewer people that will struggle with retirement.",
          "healthcare quality is much higher and patients are treated like family.",
          "healthcare is more affordable and the cost decreases over time.",
          "there is much more hope for the future.",
          "you can resolve much more conflict.",
          "there is much more respect for all people.",
          "there is much more love.",
        ],
        withouts: [
          "caring for aging parents is stressful.",
          "there are many struggling to afford retirement.",
          "there is some quality in healthcare.",
          "healthcare comes with a high cost that continually increases.",
          "there is little hope for the future.",
          "you can resolve some conflict.",
          "there is some respect.",
          "there is some love.",
        ]
      },
      { 
        label: "75+ Years",
        withs: [
          "people know just because you’re older doesn’t mean you’re useless.",
          "less doctors and nurses would be impatient.",
          "society would put a premium on my experience and wisdom.",
          "my grandchildren would ask to learn from me.",
          "families would visit their older family members and keep in touch.",
          "less people would show impatience in driving around older people.",
          "my family would be eager for me to pass on my talents.",
          "most people show interest in what I’m saying.",
        ],
        withouts: [
          "sometimes people and society still value me. ",
          "doctors and nurses can get rude and dismissive.",
          "some people respect my knowledge and wisdom.",
          "my grandchildren would sometimes want to learn from me.",
          "sometimes families neglect their older relatives.",
          "people tailgate me, pass me, and blow their horn.",
          "my family sometimes shows an interest in me.",
          "some people understand if you lose your concentration more easily.",
        ]
      }
    ],

    issueTabs: [
      { 
        label: "Community & Family",
        withs: [
            "More families spend time together and support one another.",
            "Less neighbors think about themselves and more about everyone else!",
            "I trust my local government to be fair, honest, and looking out for me.",
            "Less crime is committed.",
            "Most people maintain their property so their neighborhood does entice!",
            "Most people volunteer their treasures and are far less greedy!",
            "Less neighbors fight and instead do what’s right!",
            "There are less breakups and drama!",
        ],
        withouts: [
            "Some families show love, patience, and interest in each other.",
            "Neighbors look out for themselves.",
            "My local government is sometimes thoughtful on how their rules affect me.",
            "Crime is high.",
            "Some people maintain their property so the neighborhood looks nice.",
            "Some people volunteer their time and money to help the needy.",
            "Some neighbors get into petty squabbles and fight.",
            "Some families suffer divorce and trauma.",
        ]
      },
      {
        label: "Friendships & Relationships",
        withs: [
            "Most use less hurtful words.",
            "Many more are honest.",
            "More people take responsibility for their actions.",
            "There is more respect for others and that helps in every interaction.",
            "You can resolve and repair most conflicts in your friendships and relationships.",
            "There is more support from your friends and loved ones.",
            "You will have more good friendships and relationships.",
            "More people listen to you.",
        ],
        withouts: [
            "Some use a lot of hurtful words.",
            "Some are honest.",
            "Some people take responsibility for their actions.",
            "There is some respect for some people.",
            "You can resolve and repair some conflicts in your friendships and relationships.",
            "There is some support from your friends and loved ones.",
            "You can have some good friendships and relationships.",
            "Some people listen to you.",
        ]
      },
      {
        label: "Our Planet",
        withs: [
            "There is less pollution.",
            "There is less destruction of nature.",
            "There are less extinctions.",
            "There is more recycling and more efficient use of natural resources.",
            "There is much more research and development in clean and renewable energy.",
            "There is much more regard for nature and many more would build with nature.",
            "There is much more quality in our air and water and it would continually improve.",
            "There is far less debris and pollution in our oceans and lakes.",
        ],
        withouts: [
            "There is pollution.",
            "There is needless destruction of nature.",
            "There are many extinctions of unique species.",
            "There is some recycling and inefficient use of natural resources.",
            "There is some research and development in clean and renewable energy.",
            "There is building with some regard to nature.",
            "There is some quality in our air and water.",
            "There is a lot of debris and pollution in our oceans and lakes.",
        ]
      },
      {
        label: "Conflict & Cooperation",
        withs: [
            "People say things that are constructive and positive.",
            "There is more cooperation and it is encouraged.",
            "There is much less to worry and stress about.",
            "There is much more personal responsibility and it is encouraged.",
            "There is much more honesty and integrity.",
            "We can resolve most of our conflicts and come to even better solutions.",
            "There is more respect for one another.",
            "There is a lot of fighting.",
        ],
        withouts: [
            "People say some things that are positive.",
            "There is cooperation.",
            "There is much to worry and stress about.",
            "There is some personal responsibility.",
            "There is some honesty and integrity",
            "We can resolve some of our conflicts and come to agreeable solutions.",
            "There is some respect for one another.",
            "There is less fighting.",
        ]
      },
      {
        label: "Health",
        withs: [
            "Doctors and nurses treat you like family.",
            "There are fewer surprise costs/bills and the pricing is affordable.",
            "Staff (from the doctor to the janitor) go extra miles to make you feel at home.",
            "All doctors spend the necessary time with each person and don’t rush.",
            "Most medications are affordable.",
            "A lot of money and priority is put into research for cures.",
            "People make better food choices.",
            "There is a lot of preparation for pandemics.",
        ],
        withouts: [
            "Some doctors and nurses smile at you.",
            "There is little transparency in medical costs and bills.",
            "Some hospital staff show empathy for you being scared and vulnerable.",
            "Some doctors will spend time talking to you to make a proper diagnosis.",
            "Some medications are affordable.",
            "Some money is put into research for cures.",
            "People make some good food choices.",
            "There is some preparation for pandemics.",
        ]
      },
      {
        label: "Safety & Security",
        withs: [
            "There is less fear and it decreases over time.",
            "There is much less violent crime.",
            "Homes, workplaces, and vehicles are designed and maintained with safety as a priority.",
            "There is less theft.",
            "There are many more who respect one another.",
            "Many people look out and take care of each other.",
            "Many conflicts are resolved before becoming unsafe.",
            "More people take personal responsibility.",
        ],
        withouts: [
            "There is fear and it increases over time.",
            "There is violent crime.",
            "Home, workplaces, and vehicles are designed and maintained with some safety in mind.",
            "There is theft.",
            "There are some who respect one another.",
            "Some people look out for each other.",
            "Some conflicts are resolved before becoming unsafe.",
            "Some take personal responsibility.",
        ]
      },
      {
        label: "Food & Water",
        withs: [
            "Food is much more affordable and fairly priced.",
            "There is much less wasted food and water.",
            "There is much more access to clean food and water.",
            "There are far less dangerous chemicals and heavy metals in our water.",
            "There is more research and development into water purification.",
            "There is more research and development into efficient and healthy agriculture.",
            "Far less people would pollute and dump harmful chemicals into water sources.",
            "There is more research and development into safe pesticides.",
        ],
        withouts: [
            "Food is not very affordable.",
            "There is a lot of wasted food and water.",
            "There is some access to clean food and water.",
            "There are dangerous chemicals and heavy metals in our water.",
            "There is some research and development into water purification.",
            "There is some research and development into efficient and healthy agriculture.",
            "Many people pollute and dump harmful chemicals into water sources.",
            "There is some research and development into safe pesticides.",
        ]
      },
      {
        label: "Personal Development",
        withs: [
            "We get much less angry and don't hold onto negative thoughts.",
            "We often take responsibility for our actions.",
            "We much more likely to be honest with ourselves and others.",
            "We try to achieve our best whenever possible.",
            "You will be more attractive.",
            "You can resolve more conflicts and are better equipped to handle them.",
            "You will have more friends and support.",
            "You will be less selfish.",
        ],
        withouts: [
            "We get angry and hold onto negative thoughts.",
            "We sometimes take responsibility for our actions.",
            "We are sometimes honest with ourselves and others.",
            "We sometimes try to achieve our best.",
            "You can be attractive.",
            "You can resolve some conflicts.",
            "You can have some friends and support.",
            "You can be selfish.",
        ]
      },
      {
        label: "Education",
        withs: [
            "Education costs are lower and more fair.",
            "Education is more accessible even to those with special needs.",
            "There are many more people who make learning a positive and encouraging experience.",
            "There is much more quality in education and it continually increases.",
            "There is far less bullying and it is discouraged..",
            "Far fewer fall behind and help is readily available.",
            "Far more instructors care and use optimal teaching strategies.",
            "Far more parents care about academic performance.",
        ],
        withouts: [
            "Education costs are high and constantly increasing.",
            "Education is accessible to some.",
            "There are some people who make learning a positive and encouraging experience.",
            "There is some quality in education.",
            "There is bullying..",
            "There are some who fall behind.",
            "Some instructors care and use optimal teaching strategies.",
            "Some parents care about academic performance.",
        ]
      },
      {
        label: "Laws & Rules",
        withs: [
            "There is one guiding rule – treat others the way you want to be treated.",
            "Government is limited and serves the people.",
            "Government has a spirit of truth and goodwill.",
            "Government treats everyone equally.",
            "Government balances its budget.",
            "Government applies justice equitably.",
            "Government is a better steward.",
            "No one is above the law - not even politicians.",
        ],
        withouts: [
            "You need millions of laws and rules and regulations and you still have crime.",
            "Government is large and protects itself.",
            "Government is corrupt and partisan.",
            "Government favors special interest groups.",
            "Government overspends.",
            "Government becomes abusive and discriminatory.",
            "There is a lot of government waste.",
            "Government thinks it is above the law.",
        ]
      }
    ]
  })
}
</script>